import React, {useEffect, useState} from 'react';
import {withRouter, useHistory} from "react-router-dom";
import bluredNavbar from "../../../assets/svg/navigation.svg";
import CreatingAccountModal from "../../../ui-kit/src/specialized/userForms/creatingAccount/CreatingAccountModal";
import environment, {setAuthToken} from "../../../Environment"
import LoginMutation from "../../../mutations/LoginMutation";
import CreateCompanyUserMutation from "../../../mutations/CreateCompanyUserMutation";
import {initializeAnonymousInUser} from "../../../common/intercomIntegration";
import {UserRegistrationDataType} from "../../../ui-kit/src/specialized/userForms/creatingAccount/types";
import {PayloadError} from "relay-runtime/lib/network/RelayNetworkTypes";

function Register() {
  const [errors, setErrors] = useState<readonly PayloadError[] | Error>([])
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()

  useEffect(() => {
    initializeAnonymousInUser();
  }, [])

  const onSubmit = (data: UserRegistrationDataType) => {
    setIsLoading(true)
    CreateCompanyUserMutation(
        data,
        environment,
        onSuccess,
        onError
    )
  }

  const onSuccess = (email: string, password: string) => {
    setIsLoading(false)
    LoginMutation(
        email,
        password,
        (response) => {
          setAuthToken(response.tokenAuth.token);
          history.push("/")
        },
        (errors) => {
          onError(errors)
        }
    )
  }

  const onError = (errors: readonly PayloadError[] | Error) => {
    setErrors(errors)
    setIsLoading(false)
  }

  return <div className="app flex-row align-items-center">
    <img src={bluredNavbar} alt={"Blurred nav-bar"} className={"blured-navbar"}/>
    <CreatingAccountModal isOpen={true}
                          loading={isLoading}
                          errors={errors}
                          scope={'brands'}
                          onCancel={() => {
                            window.location.href = "https://vuuh.com";
                          }}
                          onSubmit={data => onSubmit(data)}
    />
  </div>
}

export default withRouter(Register);
