import React, {useState, useReducer, useEffect} from "react";
import styles from './CreatingAccountModal.module.scss'
import CompanyInformationForm from "./CompanyInformationForm";
import PersonalInformationForm, {registerFields} from "./PersonalInformationForm";
import GrayModal from "../../../dataDisplay/GrayModal";
import {capitalize} from "../../../stringUtils";
import CreateAccountModalLoadingStateContent from "./CreateAccountModalLoadingStateContent";
import Button from "../../../atoms/Button";
import {Separator} from "../../../atoms/Card";
import {noop} from "../../../commons/misc";
import {PayloadError} from "relay-runtime/lib/network/RelayNetworkTypes";
import {UserRegistrationDataType} from "./types";
import {doesFlagExist} from "../../../dataInput/CountryPrefixInput/CountryPrefixInput";

export const STEP_COMPANY_INFO = 0;
export const STEP_PERSONAL_INFORMATION = 1;
type ModalStepType =  typeof STEP_COMPANY_INFO | typeof STEP_PERSONAL_INFORMATION
export const PLACEHOLDER_VAT = 'XX';

export type PreFilledFieldsType = {
  vatCvr: string | null,
  companyName: string | null,
  address: string | null,
  city: string | null,
  postNumber: string | null,
  email: string | null,
  phoneNumber: string | null
} | null;

export function initialState(scope: "brands" | "retailers", preFilledFields: PreFilledFieldsType, registerToken: string | null): UserRegistrationDataType {
  return {
    checked: false,
    vatCvr: preFilledFields?.vatCvr ? preFilledFields?.vatCvr : PLACEHOLDER_VAT,
    companyName: preFilledFields?.companyName ? preFilledFields?.companyName : "",
    address: preFilledFields?.address ? preFilledFields?.address : "",
    city: preFilledFields?.city ? preFilledFields?.city : "",
    postNumber: preFilledFields?.postNumber ? preFilledFields?.postNumber : "",
    companyType: {label: capitalize(scope) as "Brands" | "Retailers", value: scope},
    firstName: "",
    lastName: "",
    email: preFilledFields?.email ? preFilledFields?.email : "",
    phoneNumber: preFilledFields?.phoneNumber ? preFilledFields?.phoneNumber : "",
    password: "",
    repeatPassword: "",
    registerToken: !!registerToken ? registerToken : null
  }
}

export function isFormComplete(currentStep: ModalStepType, state: UserRegistrationDataType) {
  if (currentStep === STEP_COMPANY_INFO) {
    return !!(
      state.vatCvr.length > 3 && doesFlagExist(state.vatCvr.slice(0, 2)) &&
      state.companyType.value &&
      state.companyName &&
      state.address &&
      state.city &&
      state.postNumber
    )
  } else {
    return !!(
      state.firstName &&
      state.lastName &&
      state.email &&
      state.password &&
      state.repeatPassword &&
      state.checked &&
      state.phoneNumber
    )
  }
}

export function _reducer(state: UserRegistrationDataType, action: {property: string, value: string | boolean}) {
  return {...state, [action.property]: action.value}
}

export function onFormProgression(
  state: UserRegistrationDataType,
  currentStep: ModalStepType,
  setCurrentStep: (val: ModalStepType) => void,
  setPrimaryButtonText: (val: string) => void,
  onSubmit: (state: UserRegistrationDataType) => void
) {
  if (currentStep === STEP_COMPANY_INFO) {
    setPrimaryButtonText('Create account')
    setCurrentStep(STEP_PERSONAL_INFORMATION)
  } else {
      onSubmit(state)
  }
}

function errorsBackendToFrontend(errors: readonly PayloadError[] | Error, setBadFields: (val: string[]) => void) {
  const errorMessages = [
    {backend: 'users.passwords_do_not_match', frontend: '*The password typed in the two fields does not match.', badFields: [registerFields.password, registerFields.repeatPassword]},
    {backend: 'users.password_too_short', frontend: '*The password you typed in is too short.', badFields: [registerFields.password]},
    {backend: 'users.password_not_strong_enough', frontend: '*The password you typed in is not strong enough.', badFields: [registerFields.password, registerFields.repeatPassword]},
    {backend: 'users.email_already_exists', frontend: '*There is already an account registered with this email address.', badFields: [registerFields.email]},
    {backend: 'users.invalid_email', frontend: '*The email address is not valid.', badFields: [registerFields.email]},
    {backend: "company.vat_not_valid", frontend: "The Vat number is invalid"},
    {backend: "company.vat_already_in_use", frontend: "This VAT number has already been used."},
    {backend: "company.name_already_in_use", frontend: "This company name already exists in our system."},
    {backend: "company.retailer_already_exists", frontend: "This company is already in the system. " +
        "If you forgot your password please follow the 'Forgot Password' link on the login page"},
    {backend: 'users.token_already_taken', frontend: '*There is already an account associated with this token.'},
    {backend: 'users.token_not_found', frontend: '*There is no active invitation for this token.'}
  ]
  if(Array.isArray(errors) && errors.length) {
    const errorMessage = errorMessages.find(message => message.backend === errors[0].message);
    if(errorMessage?.frontend) {
      setBadFields(errorMessage.badFields ? errorMessage.badFields : []);
      return [errorMessage.frontend];
    } else {
      setBadFields([]);
      return ['*There was an error on our side. Please try again!'];
    }
  } else {
    setBadFields([]);
    return [];
  }
}

type CreatingAccountModalProps = {
  isOpen: boolean,
  onCancel: () => void,
  onSubmit: (val: UserRegistrationDataType) => void,
  errors: readonly PayloadError[] | Error,
  loading: boolean,
  scope: "brands" | "retailers",
  preFilledFields?: PreFilledFieldsType,
  claimErrors?: string | null
}

export function CreatingAccountModal({isOpen, onCancel, onSubmit, errors, loading, scope, preFilledFields = null, claimErrors = null}: CreatingAccountModalProps) {
  const registerToken = new URLSearchParams(window.location.search).get('claim');
  const [state, dispatch] = useReducer(_reducer, initialState(scope, preFilledFields, registerToken))
  const [currentStep, setCurrentStep] = useState<ModalStepType>(STEP_COMPANY_INFO);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [badFields, setBadFields] = useState<string[]>([])
  const [primaryButtonText, setPrimaryButtonText] = useState("Next")

  useEffect(() => {
    setErrorMessages(errorsBackendToFrontend(errors, setBadFields))
  }, [errors])

  const onClickHandler = () => {
    setErrorMessages([])
    onFormProgression(state, currentStep, setCurrentStep, setPrimaryButtonText, onSubmit)
  }

  const onGoBackHandler = () => {
    setCurrentStep(STEP_COMPANY_INFO);
    setPrimaryButtonText("Next");
  }

  let modalContent
  if (currentStep === STEP_COMPANY_INFO) {
    modalContent = <CompanyInformationForm state={state}
                                           handleSubmit={onClickHandler}
                                           claimErrors={claimErrors}
                                           onChange={dispatch}/>
  } else {
    modalContent = <PersonalInformationForm state={state}
                                            handleSubmit={onClickHandler}
                                            isFormComplete={isFormComplete(currentStep, state)}
                                            onChange={dispatch}
                                            errorMessages={errorMessages}
                                            badFields={badFields}/>
  }

  return <GrayModal title={'Create a ' + (scope === "brands" ? "brand" : "retailer") + ' account'}
                    isOpen={isOpen}
                    customButtons={<>
                      {currentStep === STEP_PERSONAL_INFORMATION && <>
                        <Button onClick={onGoBackHandler}
                                className={'fa-regular fa-arrow-left ml-auto'}
                                data-testid="register-button-back"/>
                        <Separator className={'ml-3 my-auto'}/>
                      </>}
                      <Button color={'secondary'}
                              onClick={onCancel}
                              className={`black-text-button ${currentStep === STEP_COMPANY_INFO ? 'ml-auto' : 'ml-3'}`}
                              data-testid="register-button-cancel">
                        Cancel
                      </Button>
                      <Button color={'primary'}
                              disabled={!isFormComplete(currentStep, state) || loading}
                              onClick={onClickHandler}
                              className={'ml-3'}
                              data-testid="register-button-next">
                        {loading ? 'Creating account...' : primaryButtonText}
                      </Button>
                    </>}
                    bodyContent={loading ? <CreateAccountModalLoadingStateContent/> : modalContent}
                    className={styles.modal}
                    steps={[
                      {stepText: "Company information"},
                      {stepText: "Personal information"}
                    ]}
                    stepsIndex={currentStep}
                    toggle={noop}/>
}

export default CreatingAccountModal;
