import React, {CSSProperties, SyntheticEvent, useEffect, useRef, useState} from 'react';
import addClassNames, {getClassNames} from "../classNameUtils";
import {useClickOutside} from "../commons/hooks";
import {noop} from "../commons/misc";
import Button, {ButtonProps} from './Button'
import styles from './Dropdown.module.scss';
import {OptionsFilter} from "./dropdownCommons";
import {DropdownContextProvider, useDropdownContext} from "./dropdowns/DropdownContext";
import Loading from './Loading';
import { createPopper } from '@popperjs/core';

const DROPDOWN_ITEMS_FILTER_DISPLAY_THRESHOLD = 7

export type SelectableOption = {
  label: string | JSX.Element
  value?: string | null
  disabled?: boolean
  icon?: JSX.Element,
  className?: string,
  color?: string,
  searchBase?: string,
  dataTestId?: string
}

export type MultiOption = {
  label: string
  options: Option[]
  disabled?: boolean
  icon?: JSX.Element,
  backendFiltered?: boolean,  // if true, will skip the frontend filtering
  onSearchChange?: (op: string) => void,
  searchDelay?: number,
  dataTestId?: string
}

type Divider = {
  divider: boolean
}

export type Header = {
  header: boolean
  label: string | JSX.Element
  icon?: JSX.Element
}

type Loading = {
  loading: boolean
}

export type Option = SelectableOption | Divider | Header | Loading | MultiOption

export function isSelectableOption(option: Option): option is SelectableOption {
  return 'value' in option && (option as SelectableOption).value !== undefined;
}

export function isMultiOption(option: Option): option is MultiOption {
  return 'options' in option && (option as MultiOption).options !== undefined;
}

type MultiMenuDropdownItemProps = {
  option: MultiOption,
  onSelectOption: ((option: SelectableOption) => void) | undefined,
  index: number
};

function MultiMenuDropdownItem({option, index, onSelectOption}: MultiMenuDropdownItemProps) {
  const {setCurrentSubmenuIndex = noop, currentSubmenuIndex} = useDropdownContext();

  return <div className={styles.multiOption}
              onClick={() => {
                if (index === currentSubmenuIndex) {
                  setCurrentSubmenuIndex(null)
                } else {
                  setCurrentSubmenuIndex(index)
                }
              }}
              data-testid={option.dataTestId}>
    {option.icon && option.icon}
    {option.label}
    <i className={"fas fa-caret-right ml-auto"}/>

    {index === currentSubmenuIndex &&
      <MultiDropdownMenu options={option.options}
                         index={index}
                         searchDelay={option.searchDelay}
                         backendFiltered={option.backendFiltered}
                         onSearchChange={option.onSearchChange}
                         onSelectOption={op => {
                           onSelectOption && onSelectOption(op);
                           setCurrentSubmenuIndex(null);
                         }}/>}
  </div>;
}

type DropdownItemProps = {
  option: Option,
  onSelectOption?: (option: SelectableOption) => void,
  index: number,
  optionClassName?: string,
}

function _optionIsSelectableOption(option: Option): option is SelectableOption {
  return 'value' in option;
}

function DropdownItem({option, index, onSelectOption, optionClassName}: DropdownItemProps): JSX.Element | null {
  const {toggleDropdowns, keepOpenAfterSelect} = useDropdownContext();

  const classNames = [
    {className: styles.selectableOption, condition: true},
    {className: styles.disabled, condition: 'value' in option && option.disabled},
    {className: optionClassName, condition: !!optionClassName},
    {className: isSelectableOption(option) && option.className, condition: isSelectableOption(option) && !!option.className}
  ]

  if ('value' in option) {
    return <div className={`${addClassNames(classNames)} ${option.color ? 'text-' + option.color : ''}`}
                onClick={(ev) => {
                  ev.stopPropagation();
                  if (!option.disabled) {
                    onSelectOption && onSelectOption(option)
                    if (!keepOpenAfterSelect) {
                      toggleDropdowns && toggleDropdowns()
                    }
                  }
                }}
                data-testid={option.dataTestId}>
      {option.icon && option.icon}{option.label}
    </div>
  } else if ('divider' in option && option.divider) {
    return <hr className={styles.hr}/>
  } else if ('header' in option && option.header) {
    return <div className={styles.header}>{option.icon && option.icon}{option.label}</div>
  } else if ('loading' in option && option.loading) {
    return <div className={"d-flex justify-content-center align-items-center"}><Loading/></div>
  } else if ('options' in option) {
    return <MultiMenuDropdownItem
      index={index}
      option={option}
      onSelectOption={onSelectOption}/>
  } else if ('label' in option) {
    return <div className={`${addClassNames(classNames)}`}>
      {option.icon}{option.label}
    </div>
  } else {
    return null
  }
}

function filterOptions(options: ReadonlyArray<Option>, search: string) {
  if (!search) {
    return options;
  }

  function containsSearchString(option: SelectableOption): boolean {
    let searchBase = null;
    if (typeof option.label === "string") {
      searchBase = option.label.toLowerCase();
    } else if ('searchBase' in option) {
      searchBase = option.searchBase?.toLowerCase();
    }
    if (searchBase) {
      return searchBase.includes(search.toLowerCase())
    }
    return false;
  }

  return options
    .filter(option => (!('value' in option) || containsSearchString(option)))
    .filter((option, i, filteredOptions) => {
      // If the search array starts or ends with a divider or has 2 consecutive dividers, filter them
      if("divider" in option) {
        if(i === 0 || i === filteredOptions.length - 1) {
          return false;
        }
        if(i <= filteredOptions.length  && "divider" in filteredOptions[i + 1]) {
          return false;
        }
      }

      return true;
    });
}


export type MultiDropdownMenuProps = {
  options: ReadonlyArray<Option>,
  onSelectOption?: (option: SelectableOption) => void
  onSearchChange?: (o: string) => void,
  searchDelay?: number,
  index: number,
  backendFiltered?: boolean
}

export function MultiDropdownMenu(props: MultiDropdownMenuProps): JSX.Element | null {
  const {
    onSelectOption,
    searchDelay,
    onSearchChange,
    options,
    backendFiltered,
    index,
    ...rest
  } = props
  const [search, setSearch] = useState('')
  let isToBeOpenedLeft = false
  const {dropdownRef} = useDropdownContext()

  if (dropdownRef?.current?.offsetParent?.clientWidth && dropdownRef.current.offsetParent.clientWidth / 2 - dropdownRef.current.offsetLeft < 0) {
    isToBeOpenedLeft = true
  }

  const selectableOptions: Option[] = options.filter(option => 'value' in option)

  let filteredOptions = backendFiltered ? options : filterOptions(options, search);

  let menuClasses = addClassNames([
    {className: styles.dropdownSubMenuLeftPositioning, condition: isToBeOpenedLeft},
    {className: styles.multiDropdownMenu, condition: true}
  ])

  return <div className={menuClasses}
              onClick={ev => ev.stopPropagation()}
              {...rest}>
    {selectableOptions.length <= DROPDOWN_ITEMS_FILTER_DISPLAY_THRESHOLD ? null :
      <OptionsFilter delay={searchDelay}
                     search={search}
                     setSearch={op => {
                       setSearch(op);
                       onSearchChange && onSearchChange(op);
                     }}/>}
    <div className={styles.dropdownMenuItemContainer}>
      {filteredOptions.map((option, index) => {
        return <DropdownItem key={index} index={index} option={option} onSelectOption={onSelectOption}/>
      })}
    </div>
  </div>
}


export type DropdownMenuProps = {
  options: Option[]
  disabled?: boolean
  onSelectOption?: (option: SelectableOption) => void
  className?: string,
  menuItemClassName?: string,
  optionClassName?: string,
  isSearchable?: boolean
  width?: string
}

export function DropdownMenu(props: DropdownMenuProps): JSX.Element {
  const {
    options,
    disabled,
    onSelectOption,
    className,
    menuItemClassName,
    isSearchable = true,
    width,
    optionClassName,
    ...rest
  } = props;
  const [search, setSearch] = useState('')
  //When a page is translated using Google Translate, it changes the 'lang' attribute of the html tag
  const isUsingTranslate = document?.getElementsByTagName('html')[0]?.getAttribute('lang') !== 'en'

  const menuRef = useRef<HTMLDivElement>(null)

  const selectableOptions: Option[] = options.filter(option => 'value' in option)
  let filteredOptions = filterOptions(options, search)

  let isToBeOpenedLeft = false
  let [isToBeOpenedTop, setIsToBeOpenedTop] = useState(false)
  const {dropdownRef} = useDropdownContext()

  if (dropdownRef?.current && menuRef?.current) {
    if(isToBeOpenedTop) {
      createPopper(dropdownRef.current, menuRef.current, {
        placement: 'top-start',
      })
    } else {
      createPopper(dropdownRef.current, menuRef.current, {
        placement: 'bottom-start',
      })
    }
  }


  //216 is the width of the dropdown menu
  if (dropdownRef?.current?.offsetParent?.clientWidth && dropdownRef.current.offsetParent.clientWidth - 216 - dropdownRef.current.offsetLeft < 0) {
    isToBeOpenedLeft = true
  }

  //find the nearest parent with overflowY: hidden/scroll/auto
  let parentBlockingElement = dropdownRef?.current?.parentElement;
  while(parentBlockingElement && !["hidden", "scroll", "auto"].includes(getComputedStyle(parentBlockingElement).overflowY)) {
    parentBlockingElement = parentBlockingElement.parentElement
  }

  useEffect(() => {
    if(menuRef?.current && dropdownRef?.current) {
      const menuHeight = menuRef.current.offsetHeight;
      //bottom point if the dropdown would be opened down
      const dropdownBottomPosition = dropdownRef?.current.getBoundingClientRect().bottom + menuHeight
      //top point if the dropdown would be opened up
      const dropdownTopPosition = dropdownRef?.current.getBoundingClientRect().top - menuHeight

      const hasSpaceTop = dropdownTopPosition > (parentBlockingElement?.getBoundingClientRect().top || 0)
      const hasSpaceBottom = dropdownBottomPosition <= (parentBlockingElement?.getBoundingClientRect().bottom || window.innerHeight || document.documentElement.clientHeight)

      setIsToBeOpenedTop(!hasSpaceBottom ? hasSpaceTop : false)
    }
  }, [dropdownRef, menuRef, parentBlockingElement]);

  return <div
    className={getClassNames([
      {className: styles.dropdownMenu, condition: true},
      {className: styles.dropdownMenuLeftPositioning, condition: isToBeOpenedLeft},
      {className: className, condition: !!className}])}
    ref={menuRef}
    style={width ? {width: width} : {}}
    {...rest}
  >
    {selectableOptions.length > DROPDOWN_ITEMS_FILTER_DISPLAY_THRESHOLD && isSearchable
      ? <OptionsFilter search={search} setSearch={setSearch}/>
      : null
    }

    {/*// If using translate, force all elements to re-render on search to be properly translated*/}
    <div className={addClassNames([
      {className: styles.dropdownMenuItemContainer, condition: true},
      {className: menuItemClassName, condition: !!menuItemClassName},
    ])}
         key={isUsingTranslate ? search : undefined}>
      {filteredOptions.map((option, index) => {
        return <DropdownItem key={index} index={index} option={option} onSelectOption={onSelectOption} optionClassName={optionClassName}/>
      })}
    </div>
  </div>
}

export type DropdownToggleProps = {
  toggleDropdowns: (e?: SyntheticEvent<HTMLButtonElement>) => void,
  closeAllDropdowns: () => void,
  isOpen: boolean,
  className?: string
};

export interface DropdownProps extends ButtonProps {
  // the current value to be rendered in the toggle. Can be passed as {label: X} to fully
  // customize the displayed toggler value
  currentValue: SelectableOption | null
  // if currentValue is null, this will be displayed as the toggler content
  placeholder?: string
  options: Option[]
  onSelectOption?: (option: SelectableOption) => void
  preToggle?: boolean
  // fully customize the toggle component
  ToggleTag?: (props: DropdownToggleProps) => JSX.Element
  // fully customize the menu component (when the dropdown is open)
  // useful when you want to lazy load the content, or display the content in a non-standard way
  MenuTag?: (props: DropdownMenuProps) => JSX.Element,
  menuWidth?: string,
  style?: CSSProperties,
  optionClassName?: string,
  // if true, after you click an option, the dropdown won't close
  keepOpenAfterSelect?: boolean,
  // display the caret or not. Defaults to true
  caret?: boolean,
  // a variant of the default button toggle, which appears more highlighted
  // https://www.figma.com/file/wWTqPaRGH3ll4hhiL1xMAD/Retailers?node-id=1563%3A296147
  bordered?: boolean,
  // if there are more items than 'DROPDOWN_ITEMS_FILTER_DISPLAY_THRESHOLD', the searchBar can be disabled
  isSearchable?: boolean
}

function DropdownInner({
                         ToggleTag,
                         className,
                         placeholder,
                         color = 'secondary',
                         currentValue,
                         disabled = false,
                         options,
                         style,
                         MenuTag = DropdownMenu,
                         onSelectOption,
                         keepOpenAfterSelect = false,
                         caret = true,
                         bordered = false,
                         isSearchable = true,
                         menuWidth,
                         optionClassName,
                         ...rest
                       }: DropdownProps) {
  const {
    toggleDropdowns,
    isOpen,
    closeAllDropdowns,
    setRef
  } = useDropdownContext();

  const dropdownRef = useClickOutside(closeAllDropdowns)

  useEffect(() => {
    setRef(dropdownRef)
  }, [])

  return <div ref={dropdownRef}
              className={getClassNames([
                {className: styles.dropdownContainer, condition: true},
                {className: className, condition: !!className},
              ])}
              style={style}
  >

    {ToggleTag ?
      <ToggleTag
        isOpen={isOpen}
        toggleDropdowns={(e) => {
          toggleDropdowns();
          isOpen && e && e.currentTarget.blur()
        }}
        closeAllDropdowns={closeAllDropdowns}
        className={getClassNames([{className: styles.focus, condition: isOpen}])}/>
      :
      <Button
        onClick={(e) => {
          toggleDropdowns();
          isOpen && e.currentTarget.blur()
        }}
        className={getClassNames([
          {className: styles.focus, condition: isOpen},
          {className: styles.borderedToggle, condition: bordered}
        ])}
        style={{minHeight: "2rem"}}
        color={color}
        disabled={disabled}
        {...rest}>
        {currentValue ? currentValue.label : placeholder}
        {caret && <i style={{marginRight: 0}} className={"fas fa-angle-down " + styles.caret}/>}
      </Button>}

    {isOpen && !disabled && <MenuTag options={options} onSelectOption={onSelectOption} isSearchable={isSearchable} width={menuWidth} optionClassName={optionClassName}/>}
  </div>
}


export default function Dropdown(props: DropdownProps): JSX.Element {
  let {
    preToggle = false,
    keepOpenAfterSelect = false,
    ...rest
  } = props;
  const dropDownRef = useRef(null)

  return <DropdownContextProvider preToggle={preToggle} keepOpenAfterSelect={keepOpenAfterSelect}>
    <DropdownInner ref={dropDownRef} {...rest}/>
  </DropdownContextProvider>

}

export type MultipleSelectionDropdownProps = {
  currentValue: SelectableOption[],
  onSelectOption: (values: SelectableOption[]) => void,
} & Omit<DropdownProps, "onSelectOption" | "currentValue">


export function MultipleSelectionDropdown(props: MultipleSelectionDropdownProps): JSX.Element {
  const {currentValue, onSelectOption, options, ...rest} = props;

  let value: SelectableOption | null = {label: ''};
  if (currentValue.length === 0) {
    value = null;
  } else if (currentValue.length === 1) {
    value = {label: currentValue[0].label, value: currentValue[0].value};
  } else {
    value = {label: `${currentValue[0].label} or ${currentValue.length - 1} others`}
  }
  return <Dropdown
    keepOpenAfterSelect={true}
    currentValue={value}
    onSelectOption={(op) => {
      if (currentValue.map(op => op.value).includes(op.value)) {
        onSelectOption(currentValue.filter(selectedOp => selectedOp.value !== op.value))
      } else {
        onSelectOption([...currentValue, op])
      }
    }}
    options={options.map(op => _selectedOrNot(
      op,
      currentValue.map(v => v.value).filter(x => x) as string[])
    )}
    {...rest}/>
}


function _selectedOrNot(option: Option, values: string[]): Option {
  if (_optionIsSelectableOption(option)) {
    if (values.includes(option.value || '')) {
      return {icon: <i className="fas fa-check"/>, ...option}
    } else {
      return {icon: <div style={{display: 'inline-block', marginRight: '1.5rem'}}/>, ...option}
    }
  }
  return option
}

/**
 * A small utility hook to automatically select the option from the provided options that is selected by currentValue.
 * Useful when we only have the "value" available as the selected dropdown value, but we need to display what
 * that option as in `label`.
 *
 * Usage:
 *
 * const dropdownInfo = useDropdownState({options: [op1, op2, op3], currentValue: v1})
 *
 * <Dropdown options={dropdownInfo.options} currentValue={dropdownInfo.value} />
 */
export function useDropdownState({
                                   options,
                                   currentValue
                                 }: { options: SelectableOption[], currentValue?: string | null }) {
  return {options, value: options.filter(op => op.value === currentValue)[0] || null}
}
