import React, {useState} from "react";
import styles from './Tooltip.module.scss';
import addClassNames from "../classNameUtils";
import {capitalize} from "../stringUtils";

const DEFAULT_TOOLTIP_GAP = "0.563rem"; // between the children and the bubble, including the arrow height
const DEFAULT_TOOLTIP_GAP_NO_ARROW = "0.125rem";
const HIDE_DELAY = 100;

interface TooltipProps extends React.ComponentPropsWithoutRef<"div"> {
  children: JSX.Element,
  text: string | JSX.Element
  placement?: "top" | "topRight" | "bottom" | "bottomRight" | "left" | "right",
  mode?: "dark" | "light" | "help" | "warning" | "danger",
  tooltipGap?: string,
  arrow?: boolean,
  delay?: number,
  isDisplayed?: boolean,
  tipStyle?: React.CSSProperties, //remove
  wrapperClassName?: string,
  wrapperStyle?: React.CSSProperties, //remove
}

export default function Tooltip({
                                  children,
                                  className,
                                  text,
                                  placement = 'top',
                                  mode = 'dark',
                                  tooltipGap,
                                  arrow = true,
                                  delay = 200,
                                  isDisplayed = true,
                                  // tipStyle,
                                  wrapperClassName,
                                  // wrapperStyle,
                                  ...rest
                                }: TooltipProps) {
  const [active, setActive] = useState(false);
  let timeout: NodeJS.Timeout;

  const showTooltip = () => {
    clearInterval(timeout)
    timeout = setTimeout(() => {
      setActive(true);
    }, delay)
  }

  const hideTooltip = () => {
    clearInterval(timeout);
    timeout = setTimeout(() => {
      setActive(false);
    }, HIDE_DELAY)
  }

  let tooltipPosition;
  const finalTooltipGap = tooltipGap != null ? tooltipGap : (arrow ? DEFAULT_TOOLTIP_GAP : DEFAULT_TOOLTIP_GAP_NO_ARROW);
  if (placement === 'top' || placement === "topRight") {
    tooltipPosition = `translateY(calc(-100% - ${finalTooltipGap}))`;
  } else if (placement === 'bottom' || placement === 'bottomRight') {
    tooltipPosition = `translateY(calc(100% + ${finalTooltipGap}))`;
  } else if (placement === 'left') {
    tooltipPosition = `translateX(calc(-100% - ${finalTooltipGap}))`;
  } else if (placement === 'right') {
    tooltipPosition = `translateX(calc(100% + ${finalTooltipGap}))`;
  }

  return (
    <div
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      className={addClassNames([
        {className: styles.container, condition: true},
        {className: wrapperClassName, condition: !!wrapperClassName}
      ])}
    >
      {children}
      {isDisplayed && (
        <div
          style={active ? {
            opacity: 1,
            visibility: "visible",
            transform: tooltipPosition
          } : undefined}
          className={addClassNames([
            {className: styles.tooltip, condition: true},
            {className: styles[`tooltipMode${capitalize(mode)}`], condition: true},
            {className: styles[`tooltipPlacement${capitalize(placement)}`], condition: true},
            {className: styles.tooltipArrow, condition: arrow},
            {className: className, condition: !!className},
          ])}
        >
          <div className={styles.tooltipText}>{text}</div>
        </div>
      )}
    </div>
  )
}
