import React, {useState} from "react";
import {
  Input,
  Container,
  Row,
  Col, Form
} from "reactstrap";
import GrayModal from "../../../dataDisplay/GrayModal";
import {ErrorAlert} from "../../../commons/errors";
import {noop} from "../../../commons/misc";
import styles from './LoginModal.module.scss'
import addClassNames from "../../../classNameUtils";
import {useHistory} from "react-router";

export const loginFields = {
  email: 'Email',
  password: 'Password'
}

type LoginModalProps = {
  onSubmit: (val: {email: string, password: string}) => void,
  errors: (string | JSX.Element)[] | null,
  loading: boolean,
  badFields: string[],
}

function LoginModal({
                      onSubmit,
                      errors,
                      loading,
                      badFields,
                    }: LoginModalProps) {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory()

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({email: email, password: password})
  }

  const isFormComplete = () => {
    return email && password;
  }

  return <GrayModal isOpen={true}
                    toggle={noop}
                    style={{minWidth: "32.5rem"}}
                    title={"Sign in"}
                    primaryHeaderButton={{
                      text: "Sign In",
                      isDisabled: !isFormComplete() || loading,
                      dataTestId: "login-button-sign-in",
                      onClick: handleSubmit
                    }}
                    secondaryHeaderButton={{
                      text: "Cancel",
                      isDisabled: loading,
                      dataTestId: "login-button-cancel",
                      onClick: () => {
                        window.location.href = "https://vuuh.com"
                      },
                    }}
                    bodyContent={
                      <>
                        <Container className={"p-4 mx-4"}>
                          <Form onSubmit={handleSubmit} action={''} method={'post'}>
                            <Col className={"px-0"}>
                              <Row className={"pb-3"} noGutters>
                                <Col className={"d-flex justify-content-end align-items-center px-0"} md={2}>
                                  <label className={"mr-3 mb-0 " + styles.labelsText}>{loginFields.email}</label>
                                </Col>
                                <Col className={"px-0"}>
                                  <Input id={"email-input"}
                                         name={'email-input'}
                                         style={{width: "12.75rem"}}
                                         className={addClassNames([
                                           {
                                             className: styles.errorField,
                                             condition: Array.isArray(badFields) && !!badFields.find(field => field === loginFields.email)
                                           }
                                         ])}
                                         onChange={(event) => {
                                           setEmail(event.target.value)
                                         }}
                                         value={email}
                                         type="email" placeholder="Type in your email"/>
                                </Col>
                                <Col className={"px-0"}/>
                              </Row>
                              <Row noGutters>
                                <Col className={"d-flex justify-content-end align-items-center px-0"} md={2}>
                                  <label className={"mr-3 mb-0 " + styles.labelsText}>{loginFields.password}</label>
                                </Col>
                                <Col className={"px-0"}>
                                  <Input id={"password-input"}
                                         name={'password-input'}
                                         style={{width: "12.75rem"}}
                                         className={addClassNames([
                                           {
                                             className: styles.errorField,
                                             condition: Array.isArray(badFields) && !!badFields.find(field => field === loginFields.password)
                                           }
                                         ])}
                                         onChange={(event) => {
                                           setPassword(event.target.value)
                                         }}
                                         value={password}
                                         type="password" placeholder="Type in your password"/>
                                </Col>
                                <Col className={"px-0 d-flex align-items-center"}>
                                  <p className={"pl-1 ml-2 mb-0 " + styles.cantRememberText}
                                     onClick={() => history.push("/forgot-password")}>
                                    Can't remember
                                  </p>
                                </Col>
                              </Row>
                            </Col>
                            <button className={'d-none'} type={'submit'}/>
                          </Form>
                        </Container>
                        {errors && <ErrorAlert error={errors[0]}/>}
                      </>
                    }/>
}

export default LoginModal;
