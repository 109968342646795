import React from 'react'
import {Col, Form, FormGroup, Row} from "reactstrap";
import Checkbox from "../../../atoms/Checkbox";
import {ErrorAlert} from "../../../commons/errors";
import styles from './CreatingAccountModal.module.scss'
import HorizontalLabelInputPair from "./HorizontalLabelInputPair";
import {UserRegistrationDataType} from "./types";
import Tooltip from "../../../atoms/Tooltip";
import addClassNames from "../../../classNameUtils";


type PersonalInformationFormProps = {
  state: UserRegistrationDataType,
  onChange: (action: { property: string, value: string | boolean }) => void,
  errorMessages: Error[] | string[] | null,
  handleSubmit: () => void,
  isFormComplete: boolean,
  badFields: string[]
}

export const registerFields = {
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email',
  phoneNumber: 'Phone number',
  password: 'Password',
  repeatPassword: 'Repeat password',
}

const passwordTip = 'Please add a strong password by making sure ' +
  'it consists of at least:\n' +
  '\n' +
  '  • 8 characters\n' +
  '  • 1 number\n' +
  '  • 1 special character\n' +
  '\n' +
  'e.g. jWa8katy9A=4';

function PersonalInformationForm({
                                   state,
                                   onChange,
                                   errorMessages,
                                   handleSubmit,
                                   isFormComplete,
                                   badFields
                                 }: PersonalInformationFormProps) {
  return <Row className={"mx-0"}>
    <Col className={"px-0 " + styles.formContainer}>
      <div className={'mx-5'}>
        <Form onSubmit={handleSubmit} action={''}>
          <FormGroup>
            <HorizontalLabelInputPair labelClasses={styles.labelInput}
                                      inputClasses={addClassNames([
                                        {className: "ml-3 " + styles.formControl, condition: true},
                                        {className: styles.errorField, condition: Array.isArray(badFields) && !!badFields.find(field => field === registerFields.firstName)},
                                      ])}
                                      containerClasses={"mb-3"}
                                      name={registerFields.firstName}
                                      value={state.firstName}
                                      isRequired={true}
                                      autoComplete={false}
                                      onChange={value => onChange({property: "firstName", value: value})}
                                      data-testid={"register-input-first-name"}/>
            <HorizontalLabelInputPair labelClasses={styles.labelInput}
                                      inputClasses={addClassNames([
                                        {className: "ml-3 " + styles.formControl, condition: true},
                                        {className: styles.errorField, condition: Array.isArray(badFields) && !!badFields.find(field => field === registerFields.lastName)},
                                      ])}
                                      containerClasses={"mb-3"}
                                      name={registerFields.lastName}
                                      value={state.lastName}
                                      isRequired={true}
                                      autoComplete={false}
                                      onChange={value => onChange({property: "lastName", value: value})}
                                      data-testid={"register-input-last-name"}/>
            <HorizontalLabelInputPair labelClasses={styles.labelInput}
                                      inputClasses={addClassNames([
                                        {className: "ml-3 " + styles.formControl, condition: true},
                                        {className: styles.errorField, condition: Array.isArray(badFields) && !!badFields.find(field => field === registerFields.email)},
                                      ])}
                                      containerClasses={"mb-3"}
                                      name={registerFields.email}
                                      value={state.email}
                                      isRequired={true}
                                      autoComplete={false}
                                      onChange={value => onChange({property: "email", value: value})}
                                      data-testid={"register-input-email"}/>
            <HorizontalLabelInputPair labelClasses={styles.labelInput}
                                      inputClasses={addClassNames([
                                        {className: "ml-3 " + styles.formControl, condition: true},
                                        {className: styles.errorField, condition: Array.isArray(badFields) && !!badFields.find(field => field === registerFields.phoneNumber)},
                                      ])}
                                      containerClasses={"mb-3"}
                                      name={registerFields.phoneNumber}
                                      value={state.phoneNumber}
                                      isRequired={true}
                                      autoComplete={false}
                                      onChange={value => onChange({property: "phoneNumber", value: value})}
                                      data-testid={"register-input-phone-number"}/>
            <div className={'d-flex flex-row'}>
              <HorizontalLabelInputPair labelClasses={styles.labelInput}
                                        inputClasses={addClassNames([
                                          {className: "ml-3 " + styles.formControl, condition: true},
                                          {className: styles.errorField, condition: Array.isArray(badFields) && !!badFields.find(field => field === registerFields.password)},
                                        ])}
                                        containerClasses={"mb-3"}
                                        inputType={"password"}
                                        name={registerFields.password}
                                        value={state.password}
                                        isRequired={true}
                                        autoComplete={false}
                                        onChange={value => onChange({property: "password", value: value})}
                                        data-testid={"register-input-password"}/>
              <Tooltip
                text={passwordTip}
                style={{height: 'min-content', marginLeft: '0.75rem', marginTop: '0.35rem'}}
                tipStyle={{bottom: '0px', width: '13rem'}}
                mode={'light'}>
                <i className="fa-light fa-circle-question"/>
              </Tooltip>
            </div>
            <HorizontalLabelInputPair labelClasses={styles.labelInput}
                                      inputClasses={addClassNames([
                                        {className: "ml-3 " + styles.formControl, condition: true},
                                        {className: styles.errorField, condition: Array.isArray(badFields) && !!badFields.find(field => field === registerFields.repeatPassword)},
                                      ])}
                                      containerClasses={"mb-3"}
                                      inputType={"password"}
                                      name={registerFields.repeatPassword}
                                      value={state.repeatPassword}
                                      isRequired={true}
                                      autoComplete={false}
                                      onChange={value =>
                                        onChange({property: "repeatPassword", value: value})
                                      }
                                      data-testid={"register-input-repeat-password"}/>
            <div className={"d-flex justify-content-center mr-5"}>
              <Checkbox checked={state.checked}
                        label={<>
                          <span>I agree with the &nbsp;</span>
                          <a href={'https://vuuh.com/terms-conditions/'}
                             rel="noopener noreferrer"
                             target="_blank">
                            Terms & Conditions
                          </a>
                        </>}
                        onChange={() => {
                          onChange({property: "checked", value: !state.checked})
                        }}
                        data-testid={"register-checkbox-terms-and-conditions"}/>

            </div>
          </FormGroup>
          <button className={'d-none'} type={'submit'} disabled={!isFormComplete}/>

          {errorMessages && <ErrorAlert error={errorMessages[0]}/>}
        </Form>
      </div>
    </Col>

  </Row>
}

export default PersonalInformationForm
