import {commitMutation, Environment, graphql} from "react-relay";
import {UserRegistrationDataType} from "../ui-kit/src/specialized/userForms/creatingAccount/types";
import {PayloadError} from "relay-runtime/lib/network/RelayNetworkTypes";

const mutation = graphql`
  mutation CreateCompanyUserMutation($input: CreateCompanyUserMutationInput!) {
    createCompanyUser(input: $input) {
      user {
        id
        email
        brandSet {
          edges {
            node {
              id
              name
            }
          }
        }
      }
      company {
        id
        name
      }
    }
  }
`;

function CreateCompanyUserMutation({
                                     companyName,
                                     email,
                                     password,
                                     repeatPassword,
                                     companyType,
                                     vatCvr,
                                     address,
                                     city,
                                     postNumber,
                                     checked,
                                     phoneNumber,
                                     firstName,
                                     lastName
                                   }: UserRegistrationDataType,
                                   environment: Environment,
                                   onSuccess: (email: string, password: string) => void,
                                   onError: (errors: readonly PayloadError[] | Error) => void) {

  let input = {
    email: email,
    password: password,
    repeatPassword: repeatPassword,
    name: companyName,
    scope: companyType.value,
    vat: vatCvr,
    address: address,
    city: city,
    postNumber: postNumber,
    termsChecked: checked && "1.0",
    phoneNumber: phoneNumber,
    firstName: firstName,
    lastName: lastName
  };
  const variables = {
    input: input
  };

  commitMutation(
    environment,
    {
      mutation: mutation,
      variables: variables,
      onCompleted: (response, errors) => {
        if (errors) {
          onError(errors);
        } else if (response && !errors) {
          onSuccess(email, password);
        }
      },
      onError: (error) => {
        onError(error);
      }
    }
  )
}

export default CreateCompanyUserMutation;
